import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, NavLink, Link } from "react-router-dom";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import logoImg from '../../assets/images/logo.png';
import facebookImg from '../../assets/images/facebook.svg';
import twitterImg from '../../assets/images/twitter.svg';
import linkedinImg from '../../assets/images/linkedin.svg';
import youtubeImg from '../../assets/images/youtube.svg';
import pinterestImg from '../../assets/images/pinterest.svg';
import instagramImg from '../../assets/images/instagram.svg';
import mediumImg from '../../assets/images/medium.svg';
import mailIcon from '../../assets/images/mailIcon.svg';

import axios from "axios";
import { CONST } from "../../_config";
import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { NewsletterpostData } from '../../redux/action'


const Footer = (props) => {

    let dispatch = useDispatch();

    React.useEffect(() => { window.scrollTo(0, 0); }, []);




    const [getcontent, setContent] = useState('')
    const [Email, setEmail] = useState("")
    const [isValid, setIsValid] = useState(false);


    let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11, data12, data13, data14, data15;

    useEffect(() => {
        getHomeContent();
    }, [])

    const getHomeContent = async () => {
        let data = {
            "heading": "Footer"
        }
        let header = {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('authToken')}`
            }
        }
        let getContent = await axios.post(CONST.BACKEND_URL + '/meta/cms_content', data, header);
        console.log(getContent.data)
        if (getContent.data.status == 200) {
            setContent(getContent.data.data);
        }

    }
    data1 = getcontent && getcontent.find(elem => elem.sub_heading == "Footer Content");

    let getdata = useSelector(state => (state.user));
    console.log(getdata)

    var Pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;


    const EmailSend = async () => {
        console.log(Pattern, Email, Pattern == Email)
        if (Email == "" || Email == null || Email == undefined) {

            toast.info("kindly Enter Email",{
                toastId: 'EmailInfo',
            });
        }

        else if (Pattern.test(Email)) {


            let data = {
                email: Email
            }
            let data2 = await dispatch(NewsletterpostData(data));

            console.log(data2)

            if (data2.payload.data?.status == true) {
                toast.success(data2.payload.message, {
                    toastId: 'EmailSuccess',
                });

            }
            else {
                toast.error(data2.payload.message, {
                    toastId: 'EmailFail',
                });
            }
        }
        else {
            toast.error("Enter Valid Email", {
                toastId: 'EmailFail',
            });
            // alert('hiii')
        }
    }

    const onChangeHandler = (event) => {
        console.log(event.target.value, "eventt");
        const value = event.target.value;
        setEmail(value);
    };


    return (
        <Fragment>
            {/* <ToastContainer theme="colored" /> */}
            <div className="FtrTpDv">
                <div className="container container1">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <img src={data1 ? data1.image : <></>} />
                            <p className="col-lg-7">{data1 ? (data1.content).replace(/(<([^>]+)>)/gi, "") : <></>}</p>
                        </div>
                        <div className="col-lg-6">
                            <h6>Stay Updated with Parklands</h6>
                            <span>Join us for latest updates and better offers...!</span>
                            <div className="FtrTpDvIp mt-3">
                                <input type="text"
                                    className="form-control"
                                    placeholder="Enter Email"
                                    onChange={(event) => onChangeHandler(event)}
                                    value={Email || ""}
                                    required
                                // pattern="/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/"
                                // pattern="^[a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,15})$"


                                />
                                <button className="btn JmpBtn" onClick={EmailSend}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="FtrBtmDv">
                <div className="container container1">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <h5>Resources</h5>
                            <ul>
                                <li>
                                    <a href="#">Marketplace</a>
                                </li>
                                <li>
                                    <a href="#">Builder</a>
                                </li>
                                <li>
                                    <a href="#">Docs</a>
                                </li>
                                <li>
                                    <a href="#">Places</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5>Events</h5>
                            <ul>
                                <li>
                                    <a href="#">Events</a>
                                </li>
                                <li>
                                    <a href="#">DAO</a>
                                </li>
                                <li>
                                    <a href="#">Blog</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5>Helpful Links</h5>
                            <ul>
                                <li>
                                    <Link to="/terms">Terms of Use</Link>
                                </li>
                                <li>
                                    <Link to="/privacy">Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="#">About Us</Link>
                                </li>
                                <li>
                                    <Link to="#">ContLinkct Us</Link>
                                </li>
                                <li>
                                    <Link to="#">FAQ</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5>Follow Us</h5>
                            <div className="d-flex align-items-center flex-wrap">
                                <div className="SmInDv">
                                    <a href="#"><img src={facebookImg} /></a>
                                </div>
                                <div className="SmInDv">
                                    <a href="#"><img src={twitterImg} /></a>
                                </div>
                                <div className="SmInDv">
                                    <a href="#"><img src={linkedinImg} /></a>
                                </div>
                                <div className="SmInDv">
                                    <a href="#"><img src={youtubeImg} /></a>
                                </div>
                                <div className="SmInDv">
                                    <a href="#"><img src={pinterestImg} /></a>
                                </div>
                                <div className="SmInDv">
                                    <a href="#"><img src={instagramImg} /></a>
                                </div>
                                <div className="SmInDv">
                                    <a href="#"><img src={mediumImg} /></a>
                                </div>
                            </div>
                            <p className="mt-3"><img src={mailIcon} className="mr-2" />parklands@gmail.com</p>
                        </div>
                    </div>
                    <div className="FtrCrDv">
                        <p>©2023 All rights reserved, By Metapark</p>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}

export default Footer;