import React, { Component, Fragment, useState, useRef, useEffect, createRef  } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip } from 'reactstrap';
import classnames from 'classnames';


import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import plotsImg from '../../assets/images/plots.png';
import locationImg from '../../assets/images/location.png';
import copyImg from '../../assets/images/copy.png';
import plotsminiImg from '../../assets/images/plots-mini.png';



const PlotsDetails = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }
   
    return (
        <Fragment>
            <div className="pgCntr">
                <Header />
                    <section className="ByNwPg">
                        <div className="container">
                            <div className="row ByNwPgDv">
                                <div className="col-lg-6">
                                    <div className="ByNwPgDvL">
                                        <img src={plotsImg} className="img-fluid d-block mx-auto"/>
                                    </div>                                    
                                </div>
                                <div className="col-lg-6">
                                    <div className="ByNwPgDvR">
                                        <div className="ByNwPgDvRId">
                                            <span>#23568</span>
                                        </div>
                                        <div className="ByNwPgDvROnBy">
                                            <div className="ldv">
                                                <h4>Basel parking</h4>
                                                <div className="d-flex align-items-center">
                                                    <img src={locationImg} className="mr-2"/><span>-78, -96</span>
                                                </div>
                                            </div>
                                            <div className="rdv">
                                                <h6>Owned By</h6>
                                                <h5>Steve Johnson</h5>
                                                <div className="d-flex align-items-center rdvcpy">
                                                    <img src={copyImg} className="mr-2"/><span>0xs35df4gvbdsf3...74b</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ByNwPgDvPrcDv">
                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <div className="ByNwPgDvPrcDvL">
                                                <h6>Price</h6>
                                                <h3>23.001245 <span>KPK</span></h3>
                                                <h5>$450.0145 </h5>
                                            </div>
                                            <div className="ByNwPgDvPrcDvR">
                                                <button className="btn buynow-btn">Buy Now</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="DtsActTbs">
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '1' })}
                                                    onClick={() => { toggle('1'); }}
                                                >
                                                    <span>Details</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '2' })}
                                                    onClick={() => { toggle('2'); }}
                                                >
                                                    <span>Activity</span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">
                                                <div className="DtsActTbsCt">
                                                    <label>Landmark</label>
                                                    <p>Near Binzee Mall</p>
                                                    <label>Description</label>
                                                    <p>Land is a digital piece of real estate in the Illuvium universe that players can mine for fuels through Illuvium Zero. Fuels are tokens that are used in Illuvium games and can be traded on the marketplace. Higher-tiered lands produce more fuel.</p>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2">
                                                <div className="DtsActTbsCt">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <th>Event</th>
                                                                <th>Amount</th>
                                                                <th>From</th>
                                                                <th>To</th>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <p>Transfer</p>
                                                                        <span>2-7-2023</span>
                                                                    </td>
                                                                    <td>
                                                                        <p>12.0014 KPK</p>
                                                                        <span>$80.2245</span>
                                                                    </td>
                                                                    <td>
                                                                        <p>resenjaveerr</p>
                                                                        <span><img src={copyImg} className="mr-2"/>0x6sd4vsdv...s54v</span>
                                                                    </td>
                                                                    <td>
                                                                        <p>Verdictangel</p>
                                                                        <span><img src={copyImg} className="mr-2"/>0x654fvfd74v...5sf4g</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p>Transfer</p>
                                                                        <span>2-7-2023</span>
                                                                    </td>
                                                                    <td>
                                                                        <p>12.0014 KPK</p>
                                                                        <span>$80.2245</span>
                                                                    </td>
                                                                    <td>
                                                                        <p>resenjaveerr</p>
                                                                        <span><img src={copyImg} className="mr-2"/>0x6sd4vsdv...s54v</span>
                                                                    </td>
                                                                    <td>
                                                                        <p>Verdictangel</p>
                                                                        <span><img src={copyImg} className="mr-2"/>0x654fvfd74v...5sf4g</span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <p>Transfer</p>
                                                                        <span>2-7-2023</span>
                                                                    </td>
                                                                    <td>
                                                                        <p>12.0014 KPK</p>
                                                                        <span>$80.2245</span>
                                                                    </td>
                                                                    <td>
                                                                        <p>resenjaveerr</p>
                                                                        <span><img src={copyImg} className="mr-2"/>0x6sd4vsdv...s54v</span>
                                                                    </td>
                                                                    <td>
                                                                        <p>Verdictangel</p>
                                                                        <span><img src={copyImg} className="mr-2"/>0x654fvfd74v...5sf4g</span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                            <div className="row RltdPlts">
                                <div className="col-lg-12">
                                    <h3>Related Plots</h3>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <div className="RltdPltsDv">
                                                <div className="ByNwPgDvRId">
                                                    <span>#23568</span>
                                                </div>
                                                <img src={plotsminiImg} className="pltsdtsImg"/>
                                                <div className="RltdPltsDvDts">
                                                    <div className="RltdPltsDvDtsL">
                                                        <h5>Abyssal Basin</h5>
                                                        <p>-52, -89</p>
                                                    </div>
                                                    <div className="RltdPltsDvDtsR">
                                                        <h5>0.2447 <span>KPK</span></h5>
                                                        <p>$502.00</p>
                                                    </div>
                                                    <button className="btn buynow-btn">Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="RltdPltsDv">
                                                <div className="ByNwPgDvRId">
                                                    <span>#23568</span>
                                                </div>
                                                <img src={plotsminiImg} className="pltsdtsImg"/>
                                                <div className="RltdPltsDvDts">
                                                    <div className="RltdPltsDvDtsL">
                                                        <h5>Super Market</h5>
                                                        <p>-12, -56</p>
                                                    </div>
                                                    <div className="RltdPltsDvDtsR">
                                                        <h5>12.0014<span>KPK</span></h5>
                                                        <p>$1000.00</p>
                                                    </div>
                                                    <button className="btn buynow-btn">Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="RltdPltsDv">
                                                <div className="ByNwPgDvRId">
                                                    <span>#23568</span>
                                                </div>
                                                <img src={plotsminiImg} className="pltsdtsImg"/>
                                                <div className="RltdPltsDvDts">
                                                    <div className="RltdPltsDvDtsL">
                                                        <h5>Basel parking</h5>
                                                        <p>-78, -96</p>
                                                    </div>
                                                    <div className="RltdPltsDvDtsR">
                                                        <h5>0.89 <span>KPK</span></h5>
                                                        <p>$56.00</p>
                                                    </div>
                                                    <button className="btn buynow-btn">Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="RltdPltsDv">
                                                <div className="ByNwPgDvRId">
                                                    <span>#23568</span>
                                                </div>
                                                <img src={plotsminiImg} className="pltsdtsImg"/>
                                                <div className="RltdPltsDvDts">
                                                    <div className="RltdPltsDvDtsL">
                                                        <h5>Hunch Nozel</h5>
                                                        <p>-04, -76</p>
                                                    </div>
                                                    <div className="RltdPltsDvDtsR">
                                                        <h5>0.7456 <span>KPK</span></h5>
                                                        <p>$230.00</p>
                                                    </div>
                                                    <button className="btn buynow-btn">Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="RltdPltsDv">
                                                <div className="ByNwPgDvRId">
                                                    <span>#23568</span>
                                                </div>
                                                <img src={plotsminiImg} className="pltsdtsImg"/>
                                                <div className="RltdPltsDvDts">
                                                    <div className="RltdPltsDvDtsL">
                                                        <h5>Abyssal Basin</h5>
                                                        <p>-52, -89</p>
                                                    </div>
                                                    <div className="RltdPltsDvDtsR">
                                                        <h5>0.2447 <span>KPK</span></h5>
                                                        <p>$502.00</p>
                                                    </div>
                                                    <button className="btn buynow-btn">Buy Now</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="RltdPltsDv">
                                                <div className="ByNwPgDvRId">
                                                    <span>#23568</span>
                                                </div>
                                                <img src={plotsminiImg} className="pltsdtsImg"/>
                                                <div className="RltdPltsDvDts">
                                                    <div className="RltdPltsDvDtsL">
                                                        <h5>Super Market</h5>
                                                        <p>-12, -56</p>
                                                    </div>
                                                    <div className="RltdPltsDvDtsR">
                                                        <h5>12.0014<span>KPK</span></h5>
                                                        <p>$1000.00</p>
                                                    </div>
                                                    <button className="btn buynow-btn UlstdBtn">Unlisted</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="RltdPltsDv">
                                                <div className="ByNwPgDvRId">
                                                    <span>#23568</span>
                                                </div>
                                                <img src={plotsminiImg} className="pltsdtsImg"/>
                                                <div className="RltdPltsDvDts">
                                                    <div className="RltdPltsDvDtsL">
                                                        <h5>Basel parking</h5>
                                                        <p>-78, -96</p>
                                                    </div>
                                                    <div className="RltdPltsDvDtsR">
                                                        <h5>0.89 <span>KPK</span></h5>
                                                        <p>$56.00</p>
                                                    </div>
                                                    <button className="btn buynow-btn UlstdBtn">Unlisted</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row Crdnts">
                                <div className="col-lg-12">
                                    <h3>Coordinates</h3>
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6">
                                            <div className="CrdntsCnt">
                                                <h5><img src={locationImg} className="mr-2" />Western Gate</h5>
                                                <p>-78, -96</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="CrdntsCnt">
                                                <h5><img src={locationImg} className="mr-2" />Western Gate</h5>
                                                <p>-78, -96</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="CrdntsCnt">
                                                <h5><img src={locationImg} className="mr-2" />Western Gate</h5>
                                                <p>-78, -96</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="CrdntsCnt">
                                                <h5><img src={locationImg} className="mr-2" />Western Gate</h5>
                                                <p>-78, -96</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="CrdntsCnt">
                                                <h5><img src={locationImg} className="mr-2" />Western Gate</h5>
                                                <p>-78, -96</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-6">
                                            <div className="CrdntsCnt">
                                                <h5><img src={locationImg} className="mr-2" />Western Gate</h5>
                                                <p>-78, -96</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                <Footer />
            </div>            
        </Fragment>
    );
   
}

export default PlotsDetails;