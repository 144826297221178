import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip, Table,
    Pagination, PaginationItem, PaginationLink, Modal, ModalBody
} from 'reactstrap';
import classnames from 'classnames';

import '../Cms/cms.scss';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import bannerCms2 from "./assets/banner-cms2.jpg";

import axios from "axios";
import { CONST } from "../../_config";

import HtmlParser from "react-html-parser";

const Privacy = (props) => {


    useEffect(() => {
        getHomeContent();
    }, [])

    const [getcontent, setContent] = useState('')
    let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11, data12, data13, data14, data15;

    const getHomeContent = async () => {
        let data = {
            "heading": "Footer"
        }
        let header = {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('authToken')}`
            }
        }
        let getContent = await axios.post(CONST.BACKEND_URL + '/meta/cms_content', data, header);
        console.log(getContent.data)
        if (getContent.data.status == 200) {
            setContent(getContent.data.data);
        }

    }
    data1 = getcontent && getcontent.find(elem => elem.sub_heading == "Privacy Policy");

    return (
        <Fragment>
            <div className="pgCntr prfPg indHdv2">
                <Header />

                <div className="cmsBnr">
                    <img src={bannerCms2} alt="banner" />
                    <div className="cmsBnrTx">
                        <h2>Privacy Policy</h2>
                    </div>
                </div>

                <div className="cmsPg">
                    <div className="container">

                        {/* <h6>Privacy Policy for Metapark</h6>
                        <p>At Parklands, accessible from www.Parklands, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Parklands and how we use it.</p>
                        <br />
                        <p>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us</p>
                        <br />
                        <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Parklands. This policy is not applicable to any information collected offline or via channels other than this website.</p>
                        <br />
                        <br /> */}

                        <h6>{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h6>
                        <p>{data1 ? HtmlParser(data1.content) : <></>}</p>
                        <br />
                        <br />

                        {/* <h6>Information we collect</h6>
                        <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                        <br />
                        <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                        <br />
                        <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
                        <br />
                        <br />

                        <h6>How we use your information</h6>
                        <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>
                        <br />
                        <p>If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.</p>
                        <br />
                        <p>When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number.</p>
                        <br />
                        <br />

                        <h6>We use the information we collect in various ways, including to:</h6>
                        <br />
                        <ul>
                            <li>Provide, operate, and maintain our website</li>
                            <li>Improve, personalize, and expand our website</li>
                            <li>Understand and analyze how you use our website</li>
                            <li>Develop new products, services, features, and functionality</li>
                            <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                            <li>Send you emails</li>
                            <li>Find and prevent fraud</li>
                        </ul>
                        <br />
                        <br />

                        <h6>Log Files</h6>
                        <br />
                        <p>Parklands follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.</p>
                        <br />
                        <br />

                        <h6>Cookies and Web Beacons</h6>
                        <br />
                        <p>Like any other website, Parklands uses "cookies". These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information.</p>
                        <br />
                        <br />
                         */}


                    </div>
                </div>



                <Footer />

            </div>
        </Fragment>
    );

}

export default Privacy;