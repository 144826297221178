import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip, Table,
    Pagination, PaginationItem, PaginationLink, Modal, ModalBody
} from 'reactstrap';
import classnames from 'classnames';

import '../Cms/cms.scss';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";

import banner from "./assets/banner-cms.jpg";

import axios from "axios";
import { CONST } from "../../_config";

import HtmlParser from "react-html-parser";

const Terms = (props) => {

    useEffect(() => {
        getHomeContent();
    }, [])

    const [getcontent, setContent] = useState('')
    let data1, data2, data3, data4, data5, data6, data7, data8, data9, data10, data11, data12, data13, data14, data15;

    const getHomeContent = async () => {
        let data = {
            "heading": "Footer"
        }
        let header = {
            headers: {
                "Authorization": `Bearer ${sessionStorage.getItem('authToken')}`
            }
        }
        let getContent = await axios.post(CONST.BACKEND_URL + '/meta/cms_content', data, header);
        console.log(getContent.data)
        if (getContent.data.status == 200) {
            setContent(getContent.data.data);
        }

    }
    data1 = getcontent && getcontent.find(elem => elem.sub_heading == "Terms & Conditions");

    return (
        <Fragment>
            <div className="pgCntr prfPg indHdv2">
                <Header />

                <div className="cmsBnr">
                    <img src={banner} alt="banner" />
                    <div className="cmsBnrTx">
                        <h2>Terms & Conditions</h2>
                    </div>
                </div>

                <div className="cmsPg">
                    <div className="container">

                        {/* <h6>Terms and Conditions</h6>
                        <p>Welcome to Parklands!</p>
                        <br />
                        <p>These terms and conditions outline the rules and regulations for the use of Metapark's Website, located at www.Parklands.</p>
                        <br />
                        <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use Parklands if you do not agree to take all of the terms and conditions stated on this page.</p>
                        <br />
                        <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services, in accordance with and subject to, prevailing law of af. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
                        <br />
                        <br /> */}

                        <h6>{data1 ? HtmlParser((data1.title).replace(/(<([^>]+)>)/gi, "")) : <></>}</h6>
                        <p>{data1 ? HtmlParser(data1.content) : <></>}</p>
                        <br />
                        {/* <p>Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
                        <br />
                        <br />


                        <h6>License</h6>
                        <p>Unless otherwise stated, Metapark and/or its licensors own the intellectual property rights for all material on Parklands. All intellectual property rights are reserved. You may access this from Parklands for your own personal use subjected to restrictions set in these terms and conditions.</p>
                        <br />
                        <p>You must not:</p>
                        <br />
                        <ul>
                            <li>Republish material from Parklands</li>
                            <li>Sell, rent or sub-license material from Parklands</li>
                            <li>Reproduce, duplicate or copy material from Parklands</li>
                            <li>Redistribute content from Parklands</li>
                        </ul>
                        <br />
                        <p>This Agreement shall begin on the date hereof. Our Terms and Conditions were created with the help of the Free Terms and Conditions Generator.</p>
                        <br />
                        <p>Metapark reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>
                        <br />
                        <p>You warrant and represent that:</p>
                        <br />
                        <ul>
                            <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
                            <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
                            <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
                            <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity</li>
                        </ul>
                        <br />
                        <p>You hereby grant Metapark a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>
                        <br />
                        <br />

                        <h6>Hyperlinking to our Content</h6>
                        <p>The following organizations may link to our Website without prior written approval:</p>
                        <br />
                        <ul>
                            <li>Government agencies;</li>
                            <li>Search engines;</li>
                            <li>News organizations;</li>
                            <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
                            <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                        </ul>
                        <br />
                        <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.</p>
                        <br />
                        <br /> */}


                    </div>
                </div>



                <Footer />

            </div>
        </Fragment>
    );

}

export default Terms;