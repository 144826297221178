import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
    TabContent, TabPane, Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip, Table,
    Pagination, PaginationItem, PaginationLink, Modal, ModalBody
} from 'reactstrap';
import classnames from 'classnames';
import close from '../../assets/images/close.svg';
import '../Profile/profile.scss';

import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";


import profileImg from "./assets/profileImg.png";
import twiitter from "./assets/twiitter.svg";
import instagram from "./assets/instagram.svg";
import facebook from "./assets/facebook.svg";
import plotsminiImg from '../../assets/images/plots-mini.png';
import wallet from "./assets/wallet.svg";
import axios from "axios";
import { CONST } from "../../_config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    const [profile, setProfile] = useState('');
    const [username, setUsername] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState('');

    const [modal, setModal] = useState(false);
    const toggleWal = () => setModal(!modal);

    useEffect(() => {
        getProfile();
    }, []);

    const getProfile = async () => {
        let token = {
            headers: { "Authorization": `Bearer ${sessionStorage.getItem('authToken')}` }
        }
        let get_profile = await axios.get(CONST.BACKEND_URL + '/meta/user_profile', token);
        console.log(get_profile);
        if (get_profile.data.status == 200) {
            setProfile(get_profile.data.data);
            setUsername(get_profile.data.data.user_name);
            setDescription(get_profile.data.data.description);
            setImage(get_profile.data.data.portrait_image);
        }
    }


    const updateProfile = async () => {
        let token = {
            headers: { "Authorization": `Bearer ${sessionStorage.getItem('authToken')}` }
        }
        let data = {
            "user_name": username,
            "description": description,
            "portrait_image": image,
        }

        let update_profile = await axios.post(CONST.BACKEND_URL + '/meta/profile_update', data, token);
        console.log(update_profile);
        if (update_profile.data.status == 200) {
            toast.success('Profile Updated Successfully!!');
            setModal(false);
            getProfile();
        }
        else {
            toast.warning(update_profile.data.message)
        }
    }

    const imgupload = async (event) => {

        let reader = new FileReader();
        var file = event.target.files[0];
        var nameElement = event.target.name;
        var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        var n = (event.target.files[0].type).includes("image");
        if (!allowedExtensions.exec(event.target.files[0].name) || !n) {

        }
        else {
            let header = {
                headers: {
                    "Authorization": "Bearer " + sessionStorage.getItem('authToken')
                }
            };
            const formData = new FormData();
            formData.append('uploads', file);
            //   const options = {
            //     method: 'POST',
            //     headers: header,
            //     body: formData
            //   }
            axios.post(CONST.BACKEND_URL + `/common/single_img_upload`, formData, header)
                .then(res => {
                    console.log(res)
                    if (res.data.status == 200) {
                        setImage(res && res.data.data)
                        toast.success("File Upload sucessully");
                    }
                })
                .catch((error) => {
                    toast.error("something went wrong");
                    console.error(error)
                })
        }
    }

    return (
        <Fragment>
            {/* <ToastContainer style={{ 'zIndex': 999999 }} /> */}
            <div className="pgCntr prfPg indHdv2">
                <Header />
                <div className="prfBnr">
                    <div className="container">
                        <div className="row prfBnrR">
                            <div className="col-sm-12 col-md-12 col-lg-6 prfBnrC lt">
                                <div className="prfDtIgTx">
                                    <div className="prfDtIg">
                                        <img src={profile.portrait_image == '' || !profile.portrait_image ? profileImg : profile.portrait_image} alt="profileImg" />
                                    </div>
                                    <div className="prfDtTx">
                                        <div className="prfDtTxNm">{!profile || profile == null || profile == undefined ? '' : profile.user_name}</div>
                                        <div className="prfDtTxAdr">
                                            <span>{!profile || profile == null || profile == undefined ? '' : profile.walletAddress.slice(0, 4) + '...' + profile.walletAddress.slice(36, 42)}</span>
                                            <button className="btn" onClick={() => { navigator.clipboard.writeText(profile.walletAddress); toast.success('Address Copied!!') }}>
                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="8.79961" cy="5.2" r="5.2" fill="#8B55FF" />
                                                    <circle cx="6.2" cy="7.8001" r="5.7" fill="#8B55FF" stroke="#071053" />
                                                </svg>
                                            </button>
                                        </div>
                                        <p>
                                            {!profile || profile == null || profile == undefined ? '' : profile.description}
                                        </p>
                                        <button class="btn btn-Cmn-133-44" onClick={toggleWal}>
                                            <span>Edit Profile</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6 prfBnrC rt">
                                <div className="prfDtAsVl">
                                    <div className="row prfDtAsVlR">
                                        <div className="col col-sm-5 col-lg-5 prfDtAsVlC">
                                            <div className="prfDtAsVlNb">{!profile || profile == null || profile == undefined ? '' : profile.plots_owned}</div>
                                            <div className="prfDtAsVlLb">Plots Owned</div>
                                        </div>
                                        <div className="col col-sm-5 col-lg-5 prfDtAsVlC">
                                            <div className="prfDtAsVlNb">{!profile || profile == null || profile == undefined ? '' : profile.overall_value}</div>
                                            <div className="prfDtAsVlLb">Overall Value (KPK)</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="prfBnrScl">
                            {/* <a href="#">
                                    <img src={facebook} alt="facebook" />
                                </a>
                                <a href="#">
                                    <img src={twiitter} alt="twiitter" />
                                </a>
                                <a href="#">
                                    <img src={instagram} alt="instagram" />
                                </a> */}
                        </div>
                    </div>
                </div>

                <div className="prfTabs">
                    <div className="container">
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' })}
                                    onClick={() => { toggle('1'); }}
                                >
                                    <span>My Lands</span> ({profile && profile.my_lands.length > 0 ?profile.my_lands.length:0 })
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' })}
                                    onClick={() => { toggle('2'); }}
                                >
                                    <span>My Activity</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>

                <div className="prfTabsCont">
                    <div className="container">

                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">

                                <div className="mt-0 ByNwPg">
                                    <div className="row RltdPlts mt-0">
                                        {profile && profile.my_lands.length > 0 ?
                                            profile.my_lands.map((data, index) => (
                                                <div className="col-xl-3 col-lg-4 col-md-6">
                                                    <div className="RltdPltsDv pb-3">
                                                        <div className="ByNwPgDvRId">
                                                            <span>#23568</span>
                                                        </div>
                                                        <img src={plotsminiImg} className="pltsdtsImg mt-1" />
                                                        <div className="RltdPltsDvDts mt-3">
                                                            <div className="RltdPltsDvDtsL">
                                                                <h5>Super Market</h5>
                                                                <p>-12, -56</p>
                                                            </div>
                                                            <div className="RltdPltsDvDtsR">
                                                                <h5>
                                                                    <span>12.0014</span>
                                                                    <span>KPK</span>
                                                                </h5>
                                                                <p>$1000.00</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )) :

                                            <h3>No Data Found</h3>


                                        }

                                    </div>
                                </div>

                            </TabPane>
                            <TabPane tabId="2">

                                <div className="prfTbl">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Event</th>
                                                <th>Price</th>
                                                <th>From</th>
                                                <th>To</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {profile && profile.my_activity.length > 0 ?
                                                profile.my_lands.map((data, index) => (
                                                    <tr>
                                                        <td>Buy</td>
                                                        <td>
                                                            <div>12.0014 KPK</div>
                                                            <div className="tdTx1">$80.2245</div>
                                                        </td>
                                                        <td>
                                                            <div>resenjaveerr</div>
                                                            <div className="tdAdr">
                                                                <button className="btn">
                                                                    <img src={wallet} alt="" />
                                                                </button>
                                                                <span>
                                                                    0x6sd4vsdv...s54v
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div>Verdictangel</div>
                                                            <div className="tdAdr">
                                                                <button className="btn">
                                                                    <img src={wallet} alt="" />
                                                                </button>
                                                                <span>
                                                                    0x654fvfd74v...5sf4g
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="tdDt">
                                                            10-07-2023
                                                        </td>
                                                    </tr>
                                                )) :
                                                <tr>
                                                    <td colSpan={3}>No Data Found</td>
                                                </tr>
                                            }

                                        </tbody>
                                    </Table>
                                </div>

                                <div className="prfTblEnPg">
                                    <div className="prfTblEnTx">
                                        {/* Showing <span>10 of 200</span> Entries */}
                                    </div>

                                    <Pagination aria-label="Page navigation example">
                                        <PaginationItem>
                                            <PaginationLink previous href="#" />
                                        </PaginationItem>
                                        <PaginationItem active={true}>
                                            <PaginationLink href="#">
                                                1
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink href="#">
                                                2
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink href="#">
                                                3
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink next href="#" />
                                        </PaginationItem>
                                    </Pagination>
                                </div>

                            </TabPane>
                        </TabContent>

                    </div>
                </div>

                <Footer />
                <Modal isOpen={modal} toggle={toggleWal} centered={true} className='walConMdl' backdropClassName="walConMdlBkDp">
                    <button className="btn btnWalCls" onClick={toggleWal}>
                        <img src={close} alt="close" />
                    </button>
                    <ModalBody>
                        <div className="edtPrf">
                            <h6>Edit Profile</h6>
                            <div className="edtPrfFrm">
                                <div className="form-group text-center" onChange={imgupload}>
                                    <label className="edtPrfChIgLb" for="edtPrfImage">
                                        <span className="edtPrfIg">
                                            <img src={image == (null || undefined || '' || 'undefined') || !image ? profileImg : image} alt="profileImg" />
                                        </span>
                                        <span className="edtPrfTx">
                                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.5 12C7.82588 12.3326 8.21485 12.5968 8.64413 12.7772C9.07341 12.9576 9.53436 13.0505 10 13.0505C10.4656 13.0505 10.9266 12.9576 11.3559 12.7772C11.7852 12.5968 12.1741 12.3326 12.5 12M13 8H13.01M7 8H7.01M3 4H4C4.53043 4 5.03914 3.78929 5.41421 3.41421C5.78929 3.03914 6 2.53043 6 2C6 1.73478 6.10536 1.48043 6.29289 1.29289C6.48043 1.10536 6.73478 1 7 1H13C13.2652 1 13.5196 1.10536 13.7071 1.29289C13.8946 1.48043 14 1.73478 14 2C14 2.53043 14.2107 3.03914 14.5858 3.41421C14.9609 3.78929 15.4696 4 16 4H17C17.5304 4 18.0391 4.21071 18.4142 4.58579C18.7893 4.96086 19 5.46957 19 6V15C19 15.5304 18.7893 16.0391 18.4142 16.4142C18.0391 16.7893 17.5304 17 17 17H3C2.46957 17 1.96086 16.7893 1.58579 16.4142C1.21071 16.0391 1 15.5304 1 15V6C1 5.46957 1.21071 4.96086 1.58579 4.58579C1.96086 4.21071 2.46957 4 3 4Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                            <span>
                                                Change Photo
                                            </span>
                                        </span>
                                    </label>
                                    <input type="file" id="edtPrfImage" className="ipFle" />
                                </div>
                                <span style={{display:'flex',color:'white', fontSize:'12px', justifyContent:'center'}}>Format - JPEG[ .jpg or .jpeg ], PNG</span>
                                <div className="form-group">
                                    <label htmlFor="">Name<span>*</span></label>
                                    <input type="text"
                                        className="form-control"
                                        placeholder="Enter your name"
                                        onChange={(e) => {let value = (e.target.value).replace(/[^\w\s]/gi, ''); setUsername(value)}}
                                        value={username}
                                        required
                                        maxLength="20"
                                        onkeypress='return /[0-9a-zA-Z]/i.test(event.key)'
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Description</label>
                                    <textarea rows="4"
                                        className="form-control"
                                        placeholder="Your message here"
                                        onChange={(e) => setDescription(e.target.value)}
                                        value={description}
                                        required
                                        maxLength="160"
                                    ></textarea>
                                </div>
                                <div className="edtFrmSbm">
                                    <button className="btn btnPrm btn-12842" onClick={updateProfile}>
                                        Update Profile
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

            </div>
        </Fragment>
    );

}

export default Profile;