import React, { Component, Fragment, useState, useRef, useEffect, createRef } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import {
    Nav, NavItem, NavLink, Collapse, Navbar, Dropdown, DropdownToggle,
    DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logoImg from '../../assets/images/logo.png';
import logomobImg from '../../assets/images/logo-mob.png';
import wallet from '../../assets/images/wallet.svg';
import profile from '../../assets/images/profile.svg';
import close from '../../assets/images/close.svg';
import metamask from '../../assets/images/metamask.png';
import coinBase from '../../assets/images/coinBase.png';
import trustWallet from '../../assets/images/trustWallet.png';
import walletConnect from '../../assets/images/walletConnect.png';
import { services } from '../../connectwallet/connectwallet.js';
// import { ConnectWallet, Walletconnect } from "../../connectwallet/connectwallet";
import axios from "axios";
import { CONST } from "../../_config";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import Web3 from 'web3';
import { bscTestnet, bsc as bsc_ } from 'wagmi/chains'
import { configureChains, createConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import parkland from '../../assets/images/circles.png'
import { useAccount, useConnect, useDisconnect } from 'wagmi'
import { useDispatch, useSelector } from "react-redux";
import { siteInform } from "../../redux/action"



const WALLETCONNECT_PROJECT_ID = 'a90715942a62cf9253b0f31f65f353cc'

const bsc = {
    ...bsc_,
    rpcUrls: {
        ...bsc_.rpcUrls,
        public: {
            ...bsc_.rpcUrls.public,
            http: ['https://bsc-dataseed.binance.org/']
        },
        default: {
            ...bsc_.rpcUrls.default,
            http: ['https://bsc-dataseed.binance.org/']
        }
    }
}

export const CHAINS = [bsc, bscTestnet]

export const { chains, publicClient } = configureChains(CHAINS, [
    publicProvider()
])

export const walletConnectConnector = new WalletConnectConnector({
    chains,
    options: {
        qrcode: true,
        projectId: WALLETCONNECT_PROJECT_ID
    }
})

export const config = createConfig({
    autoConnect: true,
    publicClient,
    connectors: [walletConnectConnector]
})


const Header = (props) => {
    let web3;
    let providerType = '';
    const dispatch = useDispatch();

    const [classIsActive, setClassIsActive] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [siteinform, setSiteInform] = useState('');
    const [logo, setLogo] = useState('');
    const sideMenu = () => {
        setIsOpen(prevState => !prevState)
        setClassIsActive(!classIsActive);
        classIsActive ? document.body.classList.add('sbMenu') : document.body.classList.remove('sbMenu');
    }


    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);


    const [modal, setModal] = useState(false);
    const toggleWal = () => setModal(!modal);



    const { connectAsync, connectors } = useConnect()
    const { address } = useAccount()

    // sessionStorage.setItem('parkAccount', address);



    useEffect(() => {
        //   dispatch(getUserList());
        siteInformDetails();

    }, []);

    // let data = useSelector(state => state.user);
    // if (data.data1?.status == 200) {
    //     setSiteInform(data.data1.data);
    //     setLogo(data.data1.data.site_logo)
    // }

    const siteInformDetails = async () => {
        let data = await dispatch(siteInform());
        if (data.payload?.status == 200) {
            // toast.success(data.payload.message, {
            //     toastId: 'EmailSuccess',
            // });
            setSiteInform(data.payload.data);
            setLogo(data.payload.data.site_logo)
        }
        else {
            toast.error(data.payload.message, {
                toastId: 'EmailFail',
            });
        }

    }

    const connect = async () => {
        setModal(false)
        try {
            let address = await connectAsync({ connector: connectors[0] })
            console.log(address);
            let data = await axios.post(CONST.BACKEND_URL + '/meta/user_auth', { walletAddress: address.account })
            console.log(data)
            if (data.data.status == 200) {
                toast.success('Wallet Connected');
                sessionStorage.setItem("authToken", data.data.token);
                sessionStorage.setItem('parkAccount', address.account);

                setTimeout(() => window.location.href = '/profile', 1000);
            }

        }
        catch (e) {

        }
    }
    const { disconnect } = useDisconnect()
    const disconnectWallet = async () => {
        disconnect();
        localStorage.clear();
        sessionStorage.clear();
        toast.success('Wallet Disconnected')
        // window.location.reload();
        window.location.href = '/'
    }

    const coinbaseconnectwallet = async function () {
        // setLoading(true)
        const coinbaseWallet = new CoinbaseWalletSDK({
            appName: "Park_Land",
            appLogoUrl: parkland,
            darkMode: false,
            reloadOnDisconnect: false,
            overrideIsMetaMask: false
        })
        const coinbase = coinbaseWallet.makeWeb3Provider("https://rpc.parkscan.net/", Number(0x4C9))
        console.log(coinbase, coinbaseWallet)
        coinbase1(coinbase)


    }



    const coinbase1 = async (wallet) => {
        // setLoading(true)
        if (typeof window !== undefined) {
            web3 = new Web3(wallet);
            if (typeof window.web3 !== undefined) {
                try {
                    await wallet.enable();
                    await services.networkChange(wallet);
                    await wallet.enable().then(async (accounts) => {
                        console.log(accounts)
                        web3.eth.defaultAccount = accounts[0]
                        let address = accounts[0]
                        var User = address
                        const chainId = await web3.eth.getChainId();
                        if (chainId == CONST.chainId) {
                            // let address = accounts1[0], balanceWallet = balance / Math.pow(10, 18);
                            let Authorized = await axios.post(CONST.BACKEND_URL + '/meta/user_auth', { "walletAddress": accounts[0] });

                            // const tokenContract = await new web3.eth.Contract(tokenAbi, ContractDetails.token);
                            // await tokenContract.methods.balanceOf(accounts[0]).call(async (err, balance) => {
                            //     localStorage.setItem("snapitbalance", balance)
                            // })
                            // localStorage.setItem("Type", "coinbase")
                            // if (Authorized.data.GameData) {
                            //     // setLoading(false)
                            //     sessionStorage.setItem("bnbaccount", accounts[0]);
                            //     var balance = await web3.eth.getBalance(accounts[0]);
                            //     balance = (balance / Math.pow(10, 18)).toFixed(4);
                            //     sessionStorage.setItem("bnbbalance", balance)
                            //     localStorage.setItem('wear_id', Authorized.data.ID)
                            //     let Parseddata = JSON.parse(Authorized.data.GameData)

                            //     if (Parseddata && Parseddata.AvatarPortraitURL) {
                            //         // setLoading(false)
                            //         sessionStorage.setItem("AvatarURL", Parseddata.AvatarPortraitURL);
                            //         sessionStorage.setItem("authToken", Authorized.data.token);
                            //         toast.success("Wallet Connected")

                            //         setTimeout(() => {
                            //             // window.location.href = '/land'
                            //             window.location.href = '/marketplace'

                            //         }, 1000);
                            //     }
                            // }
                            // if (Authorized.data.token && !sessionStorage.getItem("AvatarURL")) {
                            //     // setLoading(false)
                            //     sessionStorage.setItem("bnbaccount", accounts[0]);
                            //     var balance = await web3.eth.getBalance(accounts[0]);
                            //     balance = (balance / Math.pow(10, 18)).toFixed(4);
                            //     sessionStorage.setItem("bnbbalance", balance)
                            //     sessionStorage.setItem("authToken", Authorized.data.token);
                            //     sessionStorage.setItem("redirectUrl", CONST.FRONT_URL + 'avatar');
                            //     toast.success('Wallet Connected')
                            //     // setLoading(false)
                            //     // setModal(!modal)
                            //     setTimeout(() => {
                            //         //window.location.reload()
                            //         window.location.href = '/avatar';
                            //     }, 1000);

                            // }
                            // if (Authorized.data.token && !sessionStorage.getItem("AvatarURL")) {
                            //     toast.success("Wallet Connected")
                            //     // setTimeout(() => {
                            //     //     // window.location.href = '/avatar';
                            //     //     window.location.reload()
                            //     // }, 1000);
                            // }
                            if (Authorized.data.status == 200) {
                                toast.success('Wallet Connected');
                                sessionStorage.setItem("authToken", Authorized.data.token);
                                sessionStorage.setItem('parkAccount', accounts[0]);
                                setTimeout(() => window.location.href = '/profile', 1000)
                            }
                            if (Authorized.data.status == 401) {
                                // setLoading(false)
                                // setModal(!modal)
                                toast.info(Authorized.data.msg)
                                sessionStorage.clear();
                                localStorage.clear()
                            }
                        }
                        else{
                            toast.warning('Kindly Change the Network to Koinpark')
                        }

                        // }
                    })
                }
                catch (error) {
                    // setLoading(false)
                    console.log(error)
                }
            }
        }
    }
    const metamaskconnect = async function () {
        if (!window.ethereum) {
            toast.error('Please Install Metamask');
        }
        else if (window.ethereum.providers) {
            if (window.ethereum.providers.length) {
                let Metamask = window.ethereum.providers.find(p => p.isMetaMask === true);
                console.log(Metamask)
                providerType = Metamask;
                ConnectWallet(providerType)
            }
            else {
                toast.error('Please Install Metamask')
            }
        } else {

            providerType = window.ethereum;
            console.log(window.ethereum)
            ConnectWallet(providerType)
        }

    }
    const ConnectWallet = async (wallet) => {

        try {
            // await wallet.enable();
            if (typeof window !== undefined) {
                web3 = new Web3(window.ethereum);
                if (typeof window.web3 !== undefined) {
                    await wallet.enable();

                    try {
                        // await wallet.enable();

                        await services.networkChange(wallet);
                        const accounts = await web3.eth.getAccounts();
                        // sessionStorage.setItem("parkAccount", accounts[0]);
                        const balance = await web3.eth.getBalance(accounts[0]);
                        let address = accounts[0], balanceWallet = balance / Math.pow(10, 18);


                        let first = accounts[0].substring(0, 3);
                        let last = accounts[0].substring(38, 42);
                        let shortAccount = `${first}...${last}`;

                        let data = await axios.post(CONST.BACKEND_URL + '/meta/user_auth', { walletAddress: accounts[0] })
                        console.log(data)
                        if (data.status == 200) {
                            sessionStorage.setItem('parkAccount', accounts[0])
                            setModal(false)
                            toast.success('Wallet Connected');
                            setTimeout(() => window.location.href = '/profile', 1000)
                            // if (data.data.GameData) {
                            //     localStorage.setItem('wear_id', data.data.ID)
                            //     let Parseddata = JSON.parse(data.data.GameData)
                            sessionStorage.setItem("authToken", data.data.token);

                            //     if (Parseddata && Parseddata.AvatarPortraitURL) {
                            //         sessionStorage.setItem("AvatarURL", Parseddata.AvatarPortraitURL);
                            //         toast.success('Wallet Connected')

                            //         setTimeout(() => {
                            //             window.location.href = '/profile'
                            //         }, 1000);
                            //     }
                            // }

                            // else {

                            // }

                            // if (data.data.token && !sessionStorage.getItem("AvatarURL")) {
                            //     sessionStorage.setItem("authToken", data.data.token);
                            //     sessionStorage.setItem("redirectUrl", CONST.FRONTEND_URL + 'avatar');
                            //     toast.success('Wallet Connected')

                            //     // setTimeout(() => {
                            //     //     window.location.href = '/avatar';
                            //     // }, 1000);
                            // }

                        }

                    }
                    catch (error) {
                        console.log(error)
                        // toast.error('Please Install Metamask')

                    }

                }
            }
        }
        catch (err) {

            if (window.ethereum == undefined) {
                setModal(false)
                toast.error('Please Install Metamask');
            }
            else {
                console.log(err)
                setModal(false)
                // toast.error('Please Install Metamask')Edit Profile

            }

        }

        return (
            <>
                <div><ToastContainer /></div>
            </>
        )
    }




    return (
        <Fragment>
            <ToastContainer style={{ zIndex: "999999" }}
            />
            <header class="indHd">
                <div className="container container1">
                    <Navbar className="px-0 py-2 mt-4" expand="xl">
                        <Link className="navbar-brand" to="/">
                            <img src={logo == '' || !logo ? logoImg : logo} class="img-fluid d-none d-sm-block" />
                            <img src={logomobImg} class="img-fluid d-block d-sm-none" />
                        </Link>
                        <div class="hdNavMenu mx-auto">
                            <Collapse isOpen={isOpen} navbar>
                                <Nav navbar>
                                    <NavItem>
                                        <NavLink href="#"><span>Marketplace</span></NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#"><span>Builders</span></NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#"><span>Docs</span></NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#"><span>Places</span></NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#"><span>Events</span></NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#"><span>DAO</span></NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink href="#"><span>Blogs</span></NavLink>
                                    </NavItem>
                                    {sessionStorage.getItem('parkAccount') && sessionStorage.getItem('authToken') &&
                                        <NavItem>
                                            <NavLink href="/profile"><span>Profile</span></NavLink>
                                        </NavItem>
                                    }
                                </Nav>
                            </Collapse>
                        </div>
                        <div class="mobMenu d-flex align-items-center">
                            {sessionStorage.getItem('parkAccount') == null || !sessionStorage.getItem('parkAccount') || sessionStorage.getItem('parkAccount') == undefined || sessionStorage.getItem('parkAccount') == 'undefined' || sessionStorage.getItem('parkAccount') == '' ?
                                <button class="btn btn-Cmn-133-44" onClick={toggleWal}>
                                    <span>Connect Wallet</span>
                                </button>
                                : <button class="btn btn-Cmn-133-44" onClick={disconnectWallet}>
                                    <span>{sessionStorage.getItem('parkAccount').slice(0, 4) + '...' + sessionStorage.getItem('parkAccount').slice(36, 42)}</span>
                                </button>}
                            {/*  SHOW AFTER LOGIN
                            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="hdrAdrDd">
                                <DropdownToggle caret className="hdrAdrDdBtn">
                                    <img src={wallet} alt="wallet" />
                                    <span>0xs35df4gvbdsf3...74b</span>
                                </DropdownToggle>
                                <DropdownMenu right={true}>
                                    <DropdownItem tag="a" href="/">
                                        <img src={profile} alt="profile" />
                                        <span>My Profile</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown> 
                            */}

                            <div class="smClose d-xl-none">
                                <div class={isOpen ? "smClose active" : "smClose"} onClick={sideMenu}>
                                    <svg id="closeicon" viewBox="0 0 800 600">
                                        <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                        <path d="M300,320 L460,320" id="middle"></path>
                                        <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </Navbar>
                </div>
            </header>

            <Modal isOpen={modal} toggle={toggleWal} centered={true} className='walConMdl' backdropClassName="walConMdlBkDp">
                <button className="btn btnWalCls" onClick={toggleWal}>
                    <img src={close} alt="close" />
                </button>
                <ModalBody>
                    <div className="walCon">
                        <h6>Connect Wallet</h6>
                        <div className="walConBtns">
                            <button className="btn walTypeBtn" onClick={metamaskconnect}>
                                <span className="walTypeBtn">
                                    <img src={metamask} alt="metamask" />
                                </span>
                                <span>Metamask</span>
                            </button>

                            <button className="btn walTypeBtn" onClick={connect}>
                                <span className="walTypeBtn">
                                    <img src={trustWallet} alt="trustWallet" />
                                </span>
                                <span>Trust Wallet</span>
                            </button>

                            <button className="btn walTypeBtn" onClick={coinbaseconnectwallet}>
                                <span className="walTypeBtn">
                                    <img src={coinBase} alt="coinBase" />
                                </span>
                                <span>Coinbase</span>
                            </button>

                            <button className="btn walTypeBtn" onClick={connect}>
                                <span className="walTypeBtn">
                                    <img src={walletConnect} alt="walletConnect" />
                                </span>
                                <span>Wallet Connect</span>
                            </button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        </Fragment>
    );

}

export default Header;