import Web3 from 'web3';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CONST } from '../_config/index';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import { useState } from 'react';
import parkland from '../assets/images/circles.png'




let web3;
let providerType = '';
let navigate;

export const services = {
   
    networkChange: async (wallet) => {
        console.log(wallet)
        if (typeof window.web3 !== "undefined") {
            try {
                await wallet.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: '0x4C9' }],
                }).then(() => {
                    const sucMsg = {
                        status: true,
                        message: "Correct Network",
                    };
                    // resolve (sucMsg);
                })
            } catch (switchError) {
                if (switchError.code === 4902) {
                    try {
                        await wallet.request({
                            method: 'wallet_addEthereumChain',
                            params: [
                                {
                                    chainId: "0x4C9",
                                    chainName: "Koinpark Mainnet",
                                    blockExplorerUrls: ["https://parkscan.net/"],
                                    nativeCurrency: {
                                        name: "Koinpark Mainnet",
                                        decimals: 18,
                                        symbol: "KPK",
                                    },
                                    rpcUrls: ["https://rpc.parkscan.net"],
                                },
                            ],
                        });
                    } catch (addError) {

                    }
                }
            }
        }
    },

    changeAccount: async () => {
        try {
            if (window.ethereum) {
                const isLocked = !(await window.ethereum._metamask.isUnlocked());
                if (isLocked == false) {
                    window.ethereum.on('accountsChanged', async function (accounts) {
                        localStorage.clear();
                        sessionStorage.clear();
                        // sessionStorage.setItem('account',accounts[0])
                        sessionStorage.setItem('wallet', 'metamask')
                        sessionStorage.setItem('vbcaccount', accounts[0])
                        let data = await axios.post(CONST.BACKEND_URL + '/meta/userauth', { walletAddress: accounts[0] })
                        console.log(data)
                        if (data.status == 200) {
                            if (data.data.GameData) {
                                localStorage.setItem('wear_id', data.data.ID)
                                let Parseddata = JSON.parse(data.data.GameData)
                                sessionStorage.setItem("authToken", data.data.token);

                                if (Parseddata && Parseddata.AvatarPortraitURL) {
                                    sessionStorage.setItem("AvatarURL", Parseddata.AvatarPortraitURL);
                                    toast.success('Wallet Connected')

                                    setTimeout(() => {
                                        window.location.href = '/allscenes'
                                    }, 1000);
                                }
                            }
                            else {

                            }

                            if (data.data.token && !sessionStorage.getItem("AvatarURL")) {
                                sessionStorage.setItem("authToken", data.data.token);
                                sessionStorage.setItem("redirectUrl", CONST.FRONTEND_URL + 'avatar');
                                setTimeout(() => {
                                    window.location.href = '/avatar';
                                }, 1000);
                            }

                        }



                    })
                }
            }
            else {

            }
        }
        catch (err) {

        }
    }

}

// export const connectWallet = async (wallet) => {

//     try {
//         await wallet.enable();
//         if (typeof window !== undefined) {
//             web3 = new Web3(window.ethereum);
//             if (typeof window.web3 !== undefined) {
//                 try {
//                     await wallet.enable();

//                     await services.networkChange();
//                     const accounts = await web3.eth.getAccounts();
//                     sessionStorage.setItem("vbcaccount", accounts[0]);
//                     const balance = await web3.eth.getBalance(accounts[0]);
//                     let address = accounts[0], balanceWallet = balance / Math.pow(10, 18);

//                     sessionStorage.setItem('vbcaccount', accounts[0])

//                     let first = accounts[0].substring(0, 3);
//                     let last = accounts[0].substring(38, 42);
//                     let shortAccount = `${first}...${last}`;

//                     let data = await axios.post(CONST.BACKEND_URL + '/meta/userauth', { walletAddress: accounts[0] })
//                     console.log(data)
//                     if (data.status == 200) {
//                         if (data.data.GameData) {
//                             localStorage.setItem('wear_id', data.data.ID)
//                             let Parseddata = JSON.parse(data.data.GameData)
//                             sessionStorage.setItem("authToken", data.data.token);

//                             if (Parseddata && Parseddata.AvatarPortraitURL) {
//                                 sessionStorage.setItem("AvatarURL", Parseddata.AvatarPortraitURL);
//                                 toast.success('Wallet Connected')

//                                 setTimeout(() => {
//                                     window.location.href = '/profile'
//                                 }, 1000);
//                             }
//                         }

//                         else {

//                         }

//                         if (data.data.token && !sessionStorage.getItem("AvatarURL")) {
//                             sessionStorage.setItem("authToken", data.data.token);
//                             sessionStorage.setItem("redirectUrl", CONST.FRONTEND_URL + 'avatar');
//                             toast.success('Wallet Connected')

//                             // setTimeout(() => {
//                             //     window.location.href = '/avatar';
//                             // }, 1000);
//                         }

//                     }

//                 }
//                 catch (error) {
//                     console.log(error)

//                 }

//             }
//         }
//     }
//     catch (err) {

//         if (window.ethereum == undefined) {
//             toast.error('Please Install Metamask')
//         }
//         else {
//             console.log(err)

//         }



//     }


//     return (
//         <><ToastContainer /></>
//     )

// }

// export const ConnectWallet = async (wallet) => {

//     try {
//         // await wallet.enable();
//         if (typeof window !== undefined) {
//             web3 = new Web3(window.ethereum);
//             if (typeof window.web3 !== undefined) {
//                 try {
//                     // await wallet.enable();
//                     await window.ethereum.enable();

//                     await services.networkChange();
//                     const accounts = await web3.eth.getAccounts();
//                     // sessionStorage.setItem("parkAccount", accounts[0]);
//                     const balance = await web3.eth.getBalance(accounts[0]);
//                     let address = accounts[0], balanceWallet = balance / Math.pow(10, 18);


//                     let first = accounts[0].substring(0, 3);
//                     let last = accounts[0].substring(38, 42);
//                     let shortAccount = `${first}...${last}`;

//                     let data = await axios.post(CONST.BACKEND_URL + '/meta/user_auth', { walletAddress: accounts[0] })
//                     console.log(data)
//                     if (data.status == 200) {
//                         sessionStorage.setItem('parkAccount', accounts[0])
//                         setModal(false)
//                         toast.success('Wallet Connected');
//                         setTimeout(() => window.location.href = '/profile', 1000)
//                         // if (data.data.GameData) {
//                         //     localStorage.setItem('wear_id', data.data.ID)
//                         //     let Parseddata = JSON.parse(data.data.GameData)
//                             sessionStorage.setItem("authToken", data.data.token);

//                         //     if (Parseddata && Parseddata.AvatarPortraitURL) {
//                         //         sessionStorage.setItem("AvatarURL", Parseddata.AvatarPortraitURL);
//                         //         toast.success('Wallet Connected')

//                         //         setTimeout(() => {
//                         //             window.location.href = '/profile'
//                         //         }, 1000);
//                         //     }
//                         // }

//                         // else {

//                         // }

//                         // if (data.data.token && !sessionStorage.getItem("AvatarURL")) {
//                         //     sessionStorage.setItem("authToken", data.data.token);
//                         //     sessionStorage.setItem("redirectUrl", CONST.FRONTEND_URL + 'avatar');
//                         //     toast.success('Wallet Connected')

//                         //     // setTimeout(() => {
//                         //     //     window.location.href = '/avatar';
//                         //     // }, 1000);
//                         // }

//                     }

//                 }
//                 catch (error) {
//                     console.log(error)
//                     // toast.error('Please Install Metamask')

//                 }

//             }
//         }
//     }
//     catch (err) {

//         if (window.ethereum == undefined) {
//             toast.error('Please Install Metamask')
//         }
//         else {
//             console.log(err)
//             toast.error('Please Install Metamask')

//         }



//     }



//     return (
//         <>
//             <div><ToastContainer /></div>
//         </>
//     )
// }



