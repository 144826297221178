import React, { Component, Suspense } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/scss/style.scss';
import {
   BrowserRouter, Navigate, Route, Routes
} from 'react-router-dom';

import Home from './views/Home/Home';
import PlotsDetails from './views/PlotsDetails/PlotsDetails';
import Profile from './views/Profile/Profile';
import { WagmiConfig, createConfig, mainnet } from 'wagmi'
import { createPublicClient, http } from 'viem'
import { config } from './common/Header/Header';
import Terms from './views/Cms/Terms';
import Privacy from './views/Cms/Privacy';

import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { useEffect } from "react";
import { getUserList, siteInform,NewsletterpostData } from "./redux/action";
import Unityland from './views/unityland/unityland'



const App = (props) => {
   //console.log(props.history)

   // const dispatch = useDispatch()   ;

   // useEffect(() => {
   //   dispatch(getUserList());
   //   dispatch(siteInform());
     
   // }, []);
 
   const todos = useSelector((state) => state.user);
   console.log(todos);


   return (
      <React.Fragment>
         <BrowserRouter>
            <WagmiConfig config={config}>
               <Routes history={props.history}>
                  <Route path='/' element={<Home />} />
                  <Route path='/plotsdetails' element={<PlotsDetails />} />
                  <Route path='/profile' element={<Profile />} />
                  <Route path='/terms' element={<Terms />} />
                  <Route path='/privacy' element={<Privacy />} />
                  <Route path='/land' element={<Unityland />} />

               </Routes>
            </WagmiConfig>
         </BrowserRouter>
      </React.Fragment>
   );
}

export default App;